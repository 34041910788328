/* global Component*/
class storesTukson extends Component {

    static  name() {
        return "StoresPage";
    }

    static componentName() {
        return "StoresPage";
    }

    data(){
        return {
            lon: 0,
            lat: 0,
            mapUrl: "",
        };
    }

    mounted() {
        return async function () {
            this.setActiveMap("https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.070681412087!2d-58.47491858525998!3d-34.627654080453304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc98dc1684883%3A0xfa5e8dafb346e817!2sArgerich%20419%2C%20C1406%20AME%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1612801675638!5m2!1ses-419!2sar","-34.627265","-58.473094");
        };
    }

    getMethods(){
        return {
            setActiveMap:this.setActiveMap
        };
    }

    setActiveMap(mapSrc, lon, lat) {
        this.mapUrl = mapSrc;
        this.lon = lon;
        this.lat = lat;
    }

    getTemplate() {
        return `<div class="static-page stores">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="row title">
                            <div class="col">
                                <p>Puntos Tukson</p>
                            </div>
                            <div class="col text-right">
                                <router-link class="btn" to="/PuntoTukson">¿Querés ser nuestro partner?</router-link>
                            </div>
                        </div>
                        <div class="red-message-title">
                            /// Encontrá tu punto de venta más cercano///
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <iframe id="dyn-gmap" :src="mapUrl" allowfullscreen="" aria-hidden="false" tabindex="0" frameborder="0" width="100%" height="100%"></iframe>
                            </div>
                            <ul class="col-4 list-group" style="background: #fcfafa;">
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-34.627265' && lat=='-58.473094')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3283.070681412087!2d-58.47491858525998!3d-34.627654080453304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc98dc1684883%3A0xfa5e8dafb346e817!2sArgerich%20419%2C%20C1406%20AME%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1612801675638!5m2!1ses-419!2sar','-34.627265','-58.473094')" >
                                    <div class="store-name">Argerich 419 , Flores</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 11-57569452</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs  </div>
                                </li>
                                <li class="list-group-item store_map_item"  :class="{store_map_item_active: (lon=='-34.60354854672535' && lat =='-58.4935314069974')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4259.024533342577!2d-58.495678300553195!3d-34.60019454728167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb624385a86ef%3A0x3299f79793de7!2sCuenca%202823%2C%20C1417AAM%20CABA!5e0!3m2!1ses-419!2sar!4v1641299167650!5m2!1ses-419!2sar','-34.60354854672535','-58.4935314069974')">
                                    <div class="store-name">Cuenca 2823, Villa del Parque , Ciudad de Buenos Aires</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> </div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-34.64651260903894' && lat =='-58.3758358956721')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.324167607303!2d-58.378023684173776!3d-34.64651536727224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bccb526d17cacb%3A0xb64c44bf99f9b747!2sHerrera%201739%2C%20C1295%20CABA!5e0!3m2!1ses-419!2sar!4v1641299472240!5m2!1ses-419!2sar','-34.64651260903894','-58.3758358956721')">
                                    <div class="store-name">Herrera 1739, Barracas , Ciudad de Buenos Aires</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> </div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-33.0292248' && lat =='-58.5441239')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.034376706381!2d-58.54412388510556!3d-33.02922478340256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95baa811fba3a9b9%3A0x9e33d46103802265!2sFurques%202282%2C%20Gualeguaych%C3%BA%2C%20Entre%20R%C3%ADos!5e0!3m2!1ses-419!2sar!4v1615292416519!5m2!1ses-419!2sar','-33.0292248','-58.5441239')">
                                    <div class="store-name">Furquez 2282 , Gualeguaychú</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3446612213</div>                                    
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon== '-31.1706314' && lat =='-62.1043446')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3413.806962731165!2d-62.10434458516594!3d-31.170631391525934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9434d646e514d02d%3A0x27dca502c55867b3!2sBv.%2025%20de%20Mayo%201286%2C%20Freyre%2C%20C%C3%B3rdoba!5e0!3m2!1ses-419!2sar!4v1615292144339!5m2!1ses-419!2sar','-31.1706314','-62.1043446')">
                                    <div class="store-name">Bv. 25 de mayo 1286 , Freyre</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3564462714</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-26.821995' && lat=='-65.2046414')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3560.564410172269!2d-65.20464138529638!3d-26.82199499588623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94225c181d052ebd%3A0x3075eb64c95edc64!2s25%20de%20Mayo%20659%2C%20San%20Miguel%20de%20Tucum%C3%A1n%2C%20Tucum%C3%A1n!5e0!3m2!1ses-419!2sar!4v1617218480098!5m2!1ses-419!2sar','-26.821995','-65.2046414')">
                                    <div class="store-name">25 de mayo 659 , San Miguel de Tucuman</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3816538025</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-27.5782844' && lat =='-60.7055201')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.491606212302!2d-60.70552008527489!3d-27.57828442793158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9447a9c0b8453441%3A0x90bfba7f8d4e5f92!2sTucum%C3%A1n%20130%2C%20Villa%20Angela%2C%20Chaco!5e0!3m2!1sen!2sar!4v1619094329786!5m2!1sen!2sar','-27.5782844','-60.7055201')">
                                    <div class="store-name">Tucumán 130 , Villa Angela </div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3735606932</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item":class="{store_map_item_active: (lon=='-35.0000318' && lat =='-61.0373207')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.266563874033!2d-61.037320685038615!3d-35.000031786081465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bf4b432c50657b%3A0xa3f18681420c3aad!2sM.%20Molina%20269%2C%20Los%20Toldos%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1617220136396!5m2!1ses-419!2sar','-35.0000318','-61.0373207')">
                                    <div class="store-name">Manuela Molina 269 , Los Toldos</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 2355676421</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-27.5792642' && lat =='-60.739109')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.4600184663755!2d-60.73910898527483!3d-27.579264227973585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9447a83b9fcacad3%3A0xa4dd0a99a8c71845!2sJuan%20Lorenzo%20Cobello%2C%20Villa%20Angela%2C%20Chaco!5e0!3m2!1ses-419!2sar!4v1615232811810!5m2!1ses-419!2sar','-27.5792642','-60.739109')">
                                    <div class="store-name">Lorenzo Cobello 615 , Villa Ángela</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3735411850</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-24.1858966' && lat =='-65.3075603')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3639.596023979309!2d-65.30756028536777!3d-24.185896590835494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x941b0f410be03cd5%3A0xe72757770569b326!2sGral.%20Balcarce%20263%2C%20Y4600%20San%20Salvador%20de%20Jujuy%2C%20Jujuy!5e0!3m2!1ses-419!2sar!4v1615292788694!5m2!1ses-419!2sar','-24.1858966','-65.3075603')">
                                    <div class="store-name">General Balcarce N° 263 , San Salvador De Jujuy</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3884663005</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                                <li class="list-group-item store_map_item" :class="{store_map_item_active: (lon=='-32.8197861' && lat =='-61.3990092')}" @click="setActiveMap('https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3352.9614618464566!2d-61.399009185112504!3d-32.81978607280704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c9de5dff8e6af9%3A0xc9e90f7f15bad20a!2sMoreno%20116%2C%20Ca%C3%B1ada%20de%20Gomez%2C%20Santa%20Fe!5e0!3m2!1sen!2sar!4v1617735907219!5m2!1sen!2sar','-32.8197861','-61.3990092')">
                                    <div class="store-name">Moreno 116 , Cañada de Gómez</div>
                                    <div class="store-info"><i class="fab fa-whatsapp" aria-hidden="true"></i> 3471550118</div>
                                    <div class="store-info"><i class="fas fa-location-dot" style="margin-left:0px;" aria-hidden="true"></i> L a V - 9 a 17hs </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

storesTukson.registerComponent();
